// out: ../css/app.css, compress:true

@import (less) 'reset.less';
@import (less) 'fonts.less';

@fontRegular: 'AG Book Pro Regular', sans-serif;
@fontMedium: 'AG Book Pro Medium', sans-serif;

html {
	font-size: 66.6666%;
}

body {
	font-family: @fontRegular;
	font-size: 14px;
	background: #061e4f;
	color: #fff;
	-webkit-font-smoothing: antialiased;
}

h1, h2, h3, strong {
	font-weight: normal;
}

header {
	text-align: center;
	padding: 35px 0 25px;
	h1 {
		font-family: @fontMedium;
		font-size: 33.2px;
		line-height: 40px;
		margin-bottom: 10px;
		a {
			color: #fff;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	p {
		font-size: 14px;
		line-height: 20px;
	}
}

footer {
	padding: 12px 0;
	text-align: center;
	p {
		font-size: 15px;
		line-height: 22px;
	}
	
	.links {
		margin: 25px auto 10px;
		padding: 10px 0;
		max-width: 1120px;
		border-top: 1px solid #838fa7;
		
		& > a, .social a {
			font-family: @fontMedium;
			font-size: 12px;
			color: #fff;
			text-decoration: none;
			line-height: 24px;
			margin: 0 20px 10px;
			display: inline-block;
			&:hover {
				text-decoration: underline;
				img {
					opacity: 0.5;
				}
			}
			
			img {
				transition: opacity 0.3s;
				vertical-align: top;
			}
		}
		
		.social {
			margin: 0 20px;
			display: inline-block;
			
			a {
				margin: 0;
			}
		}
	}
}

@timingShow: 0.9s;
@timingShowDelay: 0.1s;
@timingHide: 0.5s;

.items {
	.item {
		position: relative;
		transition: height @timingShow @timingShowDelay;
		height: 105px;
		overflow: hidden;
		
		&.pribehy {
			background: #66d1be url('../img/bg-pribehy.jpg') 50% 0 no-repeat;
		}
		&.nazory {
			background: #ef9535 url('../img/bg-nazory.jpg') 50% 0 no-repeat;
		}
		&.hudba {
			background: #c1a14c url('../img/bg-hudba.jpg') 50% 0 no-repeat;
		}
		&.objevy {
			background: #429965 url('../img/bg-objevy.jpg') 50% 79% no-repeat;
		}
		&.emoce {
			background: #500b33 url('../img/bg-emoce.jpg') 50% 100% no-repeat;
		}
		
		& > h2 {
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			transition: height @timingShow @timingShowDelay, line-height @timingShow @timingShowDelay, background @timingShow @timingShowDelay, opacity @timingShow @timingShowDelay;
			font-size: 28px;
			text-align: center;
			z-index: 6;
			
			span {
				left: 0; width: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			
			strong {
				font-family: @fontMedium;
			}
		}
		
		.content {
			max-width: 885px;
			padding: 30px 15px 0;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
//			opacity: 0;
			position: relative;
			z-index: 5;
			
//			transition: opacity @timingHide @timingShowDelay;
			
			.left {
				width: 240px;
				margin-right: 20px;
				
				h2 {
					font-size: 28px;
					margin-bottom: 10px;
					strong {
						font-family: @fontMedium;
					}
					transition: opacity @timingShow (@timingShowDelay + 0.1s);
					opacity: 0;
				}
				
				p {
					font-size: 14px;
					line-height: 18px;
					margin-bottom: 16px;
					opacity: 0;
					
					&.perex {
						font-size: 16px;
						line-height: 20px;
						margin-bottom: 8px;
						transition: opacity @timingShow (@timingShowDelay + 0.2s);
					}
					
					&:nth-child(3) {
						transition: opacity @timingShow (@timingShowDelay + 0.3s);
					}
					
					&:nth-child(4) {
						transition: opacity @timingShow (@timingShowDelay + 0.4s);
					}
					
					&:nth-child(5) {
						transition: opacity @timingShow (@timingShowDelay + 0.5s);
					}
					
					a {
						color: #fff;
						font-family: @fontMedium;
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
			
			.right {
				
				width: 300px;
				
				.buttons {
					opacity: 0;
					transition: opacity @timingShow (@timingShowDelay + 0.15s);
					margin-bottom: 18px;
					
					a {
						transition: background 0.3s, border 0.3s;
						flex-basis: 139px;
						display: inline-block;
						width: 138px; height: 46px;
						border: 1px solid #fff;
						border-radius: 8px;
						padding-left: 10px;
						box-sizing: border-box;
						margin: 5px 4px 6px 4px;
						
						&:hover {
							background-color: #061e4f;
							border-color: #061e4f;
						}
					}
					
				}
				
				.video {
					width: 300px;
					height: 165px;
					opacity: 0;
					transition: opacity @timingShow (@timingShowDelay + 0.3s);
					
					.youtube-player {
						position: absolute;
						top: 0; left: 0; right: 0; bottom: 0;
					}
					iframe {
						width: 100%; height: 100%;
						background: transparent;
					}
				}
				
			}
			
		}
		
		&:before {
			content: "";
			position: absolute;
			top: 0; left: 0;
			bottom: 0; right: 0;
			opacity: 0;
			z-index: 4;
		}
	}
	
	
	&:hover, &.active {
		
		.item {
			height: 66px;
			
			&.pribehy {
				& > h2 { background: #66d1be; }
				&:before { background: #66d1be; }
			}
			&.nazory {
				& > h2 { background: #ef9535; }
				&:before { background: #ef9535; }
			}
			&.hudba {
				& > h2 { background: #c1a14c; }
				&:before { background: #c1a14c; }
			}
			&.objevy {
				& > h2 { background: #429965; }
				&:before { background: #429965; }
			}
			&.emoce {
				& > h2 { background: #500b33; }
				&:before { background: #500b33; }
			}
			
			&:before {
				transition: opacity @timingShow @timingShowDelay;
				opacity: 1;
			}
			
			&:hover, &.active {
				
				&:before {
					opacity: 0;
				}
				
				& > h2 {
					transition: background 0s @timingShowDelay, opacity 0s @timingShowDelay;
					background: transparent;
					opacity: 0;
					z-index: 5;
				}
				
				height: 417px;
				
				.content {
//					transition: opacity @timingShow @timingHide;
//					opacity: 1;
					z-index: 6;
					
					.left {
						h2, p {
							opacity: 1;
						}
					}
					.right {
						.buttons, .video {
							opacity: 1;
						}
					}
				}
			}
		}
		
	}
}

@media (max-width: 767px) {
	
	header, .items .item > h2 span {
		padding-left: 15px;
		padding-right: 15px;
		text-align: left;
	}
	
	footer {
		.links {
			
		}
	}
	
	.items {
		&:hover, &.active {
			.item {
				&:hover, &.active {
					.content {
						padding-top: 20px;
					}
					&:before {
						opacity: 0.6;
					}
				}
			}
		}
	}
	
}

@media (max-width: 567px) {
	
	.items {
		&:hover, &.active {

			.pribehy{
				&:hover, &.active {
					
					height: auto;
					
					&:before {
						opacity: 1;
					}
				}
			}
			.nazory{
				&:hover, &.active {
					
					height: auto;
					
					&:before {
						opacity: 1;
					}
				}
			}
			.hudba{
				&:hover, &.active {
					
					height: auto;
					
					&:before {
						opacity: 1;
					}
				}
			}
			.objevy{
				&:hover, &.active {
					
					height: auto;
					
					&:before {
						opacity: 1;
					}
				}
			}
			.emoce{
				&:hover, &.active {
					
					height: auto;
					
					&:before {
						opacity: 1;
					}
				}
			}
			




			.item {
				
				
				.content {
					display: block;
					
					.left {
						margin-right: 0;
						width: 100%;
					}
					
					.right {
						width: 100%;
						
						.buttons {
							a {
								margin: 5px 2px 6px;
							}
						}
						
						.video {
							width: 100%;
						}
					}
				}
			}
		}
	}
	
}