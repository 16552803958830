/**
 * Global Reset of all HTML Elements
 * //gist.github.com/joshuapowell/1680540
 *
 * Resetting all of our HTML Elements ensures a smoother
 * visual transition between browsers. If you don't believe me,
 * try temporarily commenting out this block of code, then go
 * and look at Mozilla versus Safari, both good browsers with
 * a good implementation of CSS. The thing is, all browser CSS
 * defaults are different and at the end of the day if visual
 * consistency is what we're shooting for, then we need to
 * make sure we're resetting all spacing elements.
 *
 */
html,
body {
  border: 0;
  font-family: "Helvetica-Neue", "Helvetica", Arial, sans-serif;
  line-height: 1;
  margin: 0;
  padding: 0;
}
div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cit,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
canvas,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
table caption,
table th,
table td {
  text-align: left;
  vertical-align: middle;
}
a img {
  border: 0;
}
:focus {
  outline: 0;
}
@font-face {
  font-family: 'AG Book Pro Medium';
  src: url('../fonts/Berthold-AGBookPro-Medium.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/Berthold-AGBookPro-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/Berthold-AGBookPro-Medium.woff') format('woff'), /* Modern Browsers */ url('../fonts/Berthold-AGBookPro-Medium.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/Berthold-AGBookPro-Medium.svg#5107612be4c7e0f969296710dfba5067') format('svg');
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'AG Book Pro Regular';
  src: url('../fonts/Berthold-AGBookPro-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/Berthold-AGBookPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/Berthold-AGBookPro-Regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/Berthold-AGBookPro-Regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/Berthold-AGBookPro-Regular.svg#45c16b57459bec62cff2b3833945fac7') format('svg');
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
html {
  font-size: 66.6666%;
}
body {
  font-family: 'AG Book Pro Regular', sans-serif;
  font-size: 14px;
  background: #061e4f;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
strong {
  font-weight: normal;
}
header {
  text-align: center;
  padding: 35px 0 25px;
}
header h1 {
  font-family: 'AG Book Pro Medium', sans-serif;
  font-size: 33.2px;
  line-height: 40px;
  margin-bottom: 10px;
}
header h1 a {
  color: #fff;
  text-decoration: none;
}
header h1 a:hover {
  text-decoration: underline;
}
header p {
  font-size: 14px;
  line-height: 20px;
}
footer {
  padding: 12px 0;
  text-align: center;
}
footer p {
  font-size: 15px;
  line-height: 22px;
}
footer .links {
  margin: 25px auto 10px;
  padding: 10px 0;
  max-width: 1120px;
  border-top: 1px solid #838fa7;
}
footer .links > a,
footer .links .social a {
  font-family: 'AG Book Pro Medium', sans-serif;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  line-height: 24px;
  margin: 0 20px 10px;
  display: inline-block;
}
footer .links > a:hover,
footer .links .social a:hover {
  text-decoration: underline;
}
footer .links > a:hover img,
footer .links .social a:hover img {
  opacity: 0.5;
}
footer .links > a img,
footer .links .social a img {
  transition: opacity 0.3s;
  vertical-align: top;
}
footer .links .social {
  margin: 0 20px;
  display: inline-block;
}
footer .links .social a {
  margin: 0;
}
.items .item {
  position: relative;
  transition: height 0.9s 0.1s;
  height: 105px;
  overflow: hidden;
}
.items .item.pribehy {
  background: #66d1be url('../img/bg-pribehy.jpg') 50% 0 no-repeat;
}
.items .item.nazory {
  background: #ef9535 url('../img/bg-nazory.jpg') 50% 0 no-repeat;
}
.items .item.hudba {
  background: #c1a14c url('../img/bg-hudba.jpg') 50% 0 no-repeat;
}
.items .item.objevy {
  background: #429965 url('../img/bg-objevy.jpg') 50% 79% no-repeat;
}
.items .item.emoce {
  background: #500b33 url('../img/bg-emoce.jpg') 50% 100% no-repeat;
}
.items .item > h2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: height 0.9s 0.1s, line-height 0.9s 0.1s, background 0.9s 0.1s, opacity 0.9s 0.1s;
  font-size: 28px;
  text-align: center;
  z-index: 6;
}
.items .item > h2 span {
  left: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.items .item > h2 strong {
  font-family: 'AG Book Pro Medium', sans-serif;
}
.items .item .content {
  max-width: 885px;
  padding: 30px 15px 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 5;
}
.items .item .content .left {
  width: 240px;
  margin-right: 20px;
}
.items .item .content .left h2 {
  font-size: 28px;
  margin-bottom: 10px;
  transition: opacity 0.9s 0.2s;
  opacity: 0;
}
.items .item .content .left h2 strong {
  font-family: 'AG Book Pro Medium', sans-serif;
}
.items .item .content .left p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
  opacity: 0;
}
.items .item .content .left p.perex {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
  transition: opacity 0.9s 0.3s;
}
.items .item .content .left p:nth-child(3) {
  transition: opacity 0.9s 0.4s;
}
.items .item .content .left p:nth-child(4) {
  transition: opacity 0.9s 0.5s;
}
.items .item .content .left p:nth-child(5) {
  transition: opacity 0.9s 0.6s;
}
.items .item .content .left p a {
  color: #fff;
  font-family: 'AG Book Pro Medium', sans-serif;
}
.items .item .content .left p a:hover {
  text-decoration: none;
}
.items .item .content .right {
  width: 300px;
}
.items .item .content .right .buttons {
  opacity: 0;
  transition: opacity 0.9s 0.25s;
  margin-bottom: 18px;
}
.items .item .content .right .buttons a {
  transition: background 0.3s, border 0.3s;
  flex-basis: 139px;
  display: inline-block;
  width: 138px;
  height: 46px;
  border: 1px solid #fff;
  border-radius: 8px;
  padding-left: 10px;
  box-sizing: border-box;
  margin: 5px 4px 6px 4px;
}
.items .item .content .right .buttons a:hover {
  background-color: #061e4f;
  border-color: #061e4f;
}
.items .item .content .right .video {
  width: 300px;
  height: 165px;
  opacity: 0;
  transition: opacity 0.9s 0.4s;
}
.items .item .content .right .video .youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.items .item .content .right .video iframe {
  width: 100%;
  height: 100%;
  background: transparent;
}
.items .item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 4;
}
.items:hover .item,
.items.active .item {
  height: 66px;
}
.items:hover .item.pribehy > h2,
.items.active .item.pribehy > h2 {
  background: #66d1be;
}
.items:hover .item.pribehy:before,
.items.active .item.pribehy:before {
  background: #66d1be;
}
.items:hover .item.nazory > h2,
.items.active .item.nazory > h2 {
  background: #ef9535;
}
.items:hover .item.nazory:before,
.items.active .item.nazory:before {
  background: #ef9535;
}
.items:hover .item.hudba > h2,
.items.active .item.hudba > h2 {
  background: #c1a14c;
}
.items:hover .item.hudba:before,
.items.active .item.hudba:before {
  background: #c1a14c;
}
.items:hover .item.objevy > h2,
.items.active .item.objevy > h2 {
  background: #429965;
}
.items:hover .item.objevy:before,
.items.active .item.objevy:before {
  background: #429965;
}
.items:hover .item.emoce > h2,
.items.active .item.emoce > h2 {
  background: #500b33;
}
.items:hover .item.emoce:before,
.items.active .item.emoce:before {
  background: #500b33;
}
.items:hover .item:before,
.items.active .item:before {
  transition: opacity 0.9s 0.1s;
  opacity: 1;
}
.items:hover .item:hover,
.items.active .item:hover,
.items:hover .item.active,
.items.active .item.active {
  height: 417px;
}
.items:hover .item:hover:before,
.items.active .item:hover:before,
.items:hover .item.active:before,
.items.active .item.active:before {
  opacity: 0;
}
.items:hover .item:hover > h2,
.items.active .item:hover > h2,
.items:hover .item.active > h2,
.items.active .item.active > h2 {
  transition: background 0s 0.1s, opacity 0s 0.1s;
  background: transparent;
  opacity: 0;
  z-index: 5;
}
.items:hover .item:hover .content,
.items.active .item:hover .content,
.items:hover .item.active .content,
.items.active .item.active .content {
  z-index: 6;
}
.items:hover .item:hover .content .left h2,
.items.active .item:hover .content .left h2,
.items:hover .item.active .content .left h2,
.items.active .item.active .content .left h2,
.items:hover .item:hover .content .left p,
.items.active .item:hover .content .left p,
.items:hover .item.active .content .left p,
.items.active .item.active .content .left p {
  opacity: 1;
}
.items:hover .item:hover .content .right .buttons,
.items.active .item:hover .content .right .buttons,
.items:hover .item.active .content .right .buttons,
.items.active .item.active .content .right .buttons,
.items:hover .item:hover .content .right .video,
.items.active .item:hover .content .right .video,
.items:hover .item.active .content .right .video,
.items.active .item.active .content .right .video {
  opacity: 1;
}
@media (max-width: 767px) {
  header,
  .items .item > h2 span {
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
  }
  .items:hover .item:hover .content,
  .items.active .item:hover .content,
  .items:hover .item.active .content,
  .items.active .item.active .content {
    padding-top: 20px;
  }
  .items:hover .item:hover:before,
  .items.active .item:hover:before,
  .items:hover .item.active:before,
  .items.active .item.active:before {
    opacity: 0.6;
  }
}
@media (max-width: 567px) {
  .items:hover .pribehy:hover,
  .items.active .pribehy:hover,
  .items:hover .pribehy.active,
  .items.active .pribehy.active {
    height: auto;
  }
  .items:hover .pribehy:hover:before,
  .items.active .pribehy:hover:before,
  .items:hover .pribehy.active:before,
  .items.active .pribehy.active:before {
    opacity: 1;
  }
  .items:hover .nazory:hover,
  .items.active .nazory:hover,
  .items:hover .nazory.active,
  .items.active .nazory.active {
    height: auto;
  }
  .items:hover .nazory:hover:before,
  .items.active .nazory:hover:before,
  .items:hover .nazory.active:before,
  .items.active .nazory.active:before {
    opacity: 1;
  }
  .items:hover .hudba:hover,
  .items.active .hudba:hover,
  .items:hover .hudba.active,
  .items.active .hudba.active {
    height: auto;
  }
  .items:hover .hudba:hover:before,
  .items.active .hudba:hover:before,
  .items:hover .hudba.active:before,
  .items.active .hudba.active:before {
    opacity: 1;
  }
  .items:hover .objevy:hover,
  .items.active .objevy:hover,
  .items:hover .objevy.active,
  .items.active .objevy.active {
    height: auto;
  }
  .items:hover .objevy:hover:before,
  .items.active .objevy:hover:before,
  .items:hover .objevy.active:before,
  .items.active .objevy.active:before {
    opacity: 1;
  }
  .items:hover .emoce:hover,
  .items.active .emoce:hover,
  .items:hover .emoce.active,
  .items.active .emoce.active {
    height: auto;
  }
  .items:hover .emoce:hover:before,
  .items.active .emoce:hover:before,
  .items:hover .emoce.active:before,
  .items.active .emoce.active:before {
    opacity: 1;
  }
  .items:hover .item .content,
  .items.active .item .content {
    display: block;
  }
  .items:hover .item .content .left,
  .items.active .item .content .left {
    margin-right: 0;
    width: 100%;
  }
  .items:hover .item .content .right,
  .items.active .item .content .right {
    width: 100%;
  }
  .items:hover .item .content .right .buttons a,
  .items.active .item .content .right .buttons a {
    margin: 5px 2px 6px;
  }
  .items:hover .item .content .right .video,
  .items.active .item .content .right .video {
    width: 100%;
  }
}
